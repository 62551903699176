<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <chart-card
        v-if="loaded"
        header-animation="false"
        :chart-data="multipleBarsChart.data"
        :chart-options="multipleBarsChart.options"
        chart-type="Bar"
        header-icon
        chart-inside-content
        no-footer
        background-color="green"
      >
        <template slot="chartInsideHeader">
          <h4 class="title">
            Income / Outcome
          </h4>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-size-30 md-small-size-100">
      <md-card class="">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">
            Generate Monthly Report
          </h4>
        </md-card-header>

        <md-card-content>
          <YearsSelect
            v-model="report_monthly_year"
            label="Select Year..."
          />
          <md-button
            class="md-info md-block"
            @click="downloadReport('monthly')"
          >
            Download
          </md-button>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-30 md-small-size-100">
      <md-card class="">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">
            Generate Weekly Report
          </h4>
        </md-card-header>

        <md-card-content>
          <YearsSelect
            v-model="report_weekly_year"
            label="Select Year..."
          />
          <md-button
            class="md-info md-block"
            @click="downloadReport('weekly')"
          >
            Download
          </md-button>
        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-30 md-small-size-100">
      <md-card class="">
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">
            Detailed Monthly Report
          </h4>
        </md-card-header>

        <md-card-content>
          <YearsSelect
            v-model="report_detailed_year"
            label="Select Year..."
          />
          <MonthsSelect
            v-model="report_detailed_month"
            label="Select Month..."
          />
          <md-button
            class="md-info md-block"
            @click="downloadReport('detailed')"
          >
            Download
          </md-button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ChartCard } from '@/components';
import { YearsSelect, MonthsSelect } from '@/components/Inputs/selects';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    ChartCard,
    YearsSelect,
    MonthsSelect,
  },
  props: [],
  data() {
    return {
      report_weekly_year: moment().format('Y'),
      report_monthly_year: moment().format('Y'),
      report_detailed_year: moment().format('Y'),
      report_detailed_month: moment().format('M'),
      multipleBarsChart: {
        data: {
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          series: [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          ],
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false,
          },
          height: '300px',
        },
        responsiveOptions: [
          [
            'screen and (max-width: 640px)',
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      loaded: false,
    };
  },
  mounted() {
    this.getReportData();
  },
  methods: {
    getReportData() {
      this.loaded = false;
      this.request(`${this.$API.MONEYFLOW}report`, 'get', {
        year: moment().format('Y'),
      }, ({ data }) => {
        data.forEach((x, index) => {
          this.multipleBarsChart.data.series[0][x.month - 1] = x.income;
          this.multipleBarsChart.data.series[1][x.month - 1] = x.outcome * -1;
          if (index + 1 === data.length) this.loaded = true;
        });
        this.loaded = true;
      });
    },
    downloadReport(type) {
      this.loaded = false;
      this.request(`${this.$API.MONEYFLOW}report`, 'download', {
        download: true,
        year: this[`report_${type}_year`],
        month: this[`report_${type}_month`],
        type,
      }, ({ data }) => {
        const filename = `Moneyflow_${type}_report${type === 'detailed' ? `_${this[`report_${type}_month`]}` : ''}_${this[`report_${type}_year`]}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loaded = true;
      });
    },
  },
};
</script>

<style scoped>
</style>
